<template>
    <div class="modal-slot">
        <div class="head flex-row flex-between items-start m-b-24">
            <div class="title" :class="options.titleClass" v-html="$translate(options.title)" />
            <i class="zmdi zmdi-close" @click="$emit('close')" />
        </div>
        <component :is="options.component" :options="options.options" />
        <div v-if="buttons" class="buttons bottom">
            <div class="flex-row">
                <button @click="onClickButton(btn)" :key="idx" v-for="(btn, idx) in buttons" :class="btn.class">
                    {{ btn.label | translate }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalSlot',
    props: ['options'],
    mounted() {
        document.addEventListener('keydown', this.onKeydown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
    },
    computed: {
        title() {
            return (this.options || {}).title
        },
        buttons() {
            return (this.options || {}).buttons || []
        },
    },
    methods: {
        onClickButton(btn) {
            this.$emit('close', this.buttons.indexOf(btn))
        },
        onKeydown(event) {
            if (event.key === 'Enter' && this.buttons) {
                const confirmButton = this.buttons[this.buttons.length - 1]
                if (confirmButton) this.onClickButton(confirmButton)
            }
        },
    },
}
</script>
